/* normalize styling across browsers */
html {
  width: 100%;
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background: var(--blue);
  color: white;
}

h1,
h2,
h3,
h4,
h5,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
a {
  font-family: inherit;
  color: inherit;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: var(--font-family-serif);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative;
}

#__next {
  width: 100%;
  height: 100%;
}

input,
textarea,
select {
  all: initial;
  box-sizing: border-box;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

img {
  width: 100%;
  height: auto;
  border: 0;
}

video {
  width: 100%;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

p a,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
span a {
  letter-spacing: normal;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

strong {
  font-weight: 500;
}

hr {
  margin: 0;
  height: 1px;
  width: 100%;
  border: none;
}

.link {
  color: var(--blue);
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, currentColor 100%, currentColor 0);
  background-size: 1px 1px;
  background-position: 0 100%;
  white-space: initial;
}

/* .underline {
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, currentColor 100%, currentColor 0);
  background-size: 2px 2px;
  background-position: 0 100%;
  white-space: initial;
} */
