:root {
  --font-family-serif: Orpheus, Garamond, serif;
  --font-family-sans-serif: Roboto, sans-serif;

  --blue: #3bbdb6;
  --light-blue: #6ac2bd;
  --lead: #212121;
  --light-gray: #f5f5f5;
  --gray: #999999;
  --red: #ff0000;
  --dark: #222;

  --border-radius: 0.8em;

  --box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);

  --z-index-bottom: -1;
  --z-index-above: 1;
  --z-index-buoyant: 2;
  --z-index-top: 3;

  --max-width-inner: 700px;
  --max-width: 1200px;
  --max-width-outer: 1600px;
}
