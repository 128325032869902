@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family:  "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  color: #222222;
}

body #root {
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  font-family: var(--font-family-sans-serif);
  font-weight: 500;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
button:hover {
  opacity: 0.7;
}

button:not(.transparent, .quaternary):hover {
  box-shadow: var(--box-shadow);
}

button.has-icon .label {
  text-align: left;
}

button.has-icon.has-left-icon .label {
  padding-left: 10px;
}

button:focus {
  outline: 0;
}

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  z-index: 999;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}
.ReactModal__Content:focus {
  outline: 0;
}
/* 

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}


.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

*/

.PhoneInputInput {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: transparent !important;
}

.PhoneInputCountryIcon {
  width: 0.9rem;
  height: 0.6rem;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(243, 244, 246, 1) inset !important;
} */

.avatar-container:hover .email-hover {
  opacity: 1 !important;
  background-color: #00000080;
}

.member-table-row .detail-button {
  display: none;
}
.member-table-row:hover .detail-button {
  display: block;
}

.toggle-bg:after {
  content: '';
  @apply absolute left-0.5 bg-white rounded-full transition shadow-sm !important;
  /* height: 14.27px !important;
  width: 14.27px !important; */
  top: 1px !important;
  @apply h-22-27 w-22-27 sm:w-14-27 sm:h-14-27;
}

input:checked + .toggle-bg:after {
  transform: translateX(80%) !important;
  @apply border-white !important;
}

input:checked + .toggle-bg {
  @apply bg-primary-green border-primary-green !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  -webkit-text-fill-color: #222222 !important;
}